



































































































































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import { ApiResponse, UserData } from '@/interfaces'
import { mapState } from 'vuex'

export default Vue.extend({
  components: { Nav },
  data() {
    return {
      busy: false,
      isDisabled: false,
      userData: {
        email: null,
        firstName: null,
        lastName: null,
        tmsLogin: null,
        tmsRefreshToken: null,
        tmsRefreshTokenValidity: null,
        twoFactorCode: null,
        twoFactorValidUntil: null,
        autobizAccessToken: null,
        autobizTokenExpiresOn: null,
        oldUserId: null,
        last_ip: null,
      } as UserData,
      viewportWidth: 0,
      autocompleteNone: 'none',
      changeDetected: null as null | boolean,
      oldUser: null as null | number,
      clientId: null,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      this.setUserData(newVal)
      this.clientId = newVal.client.id
    },
    userData: {
      deep: true,
      immediate: true,
      handler() {
        if (this.changeDetected === false) {
          this.changeDetected = true
        }
      },
    },
  },
  mounted() {
    this.viewportWidth = window.innerWidth
    if (this.user) {
      this.setUserData(this.user)
    }
    this.$nextTick(() => {
      this.changeDetected = false
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    setAttribute() {
      this.autocompleteNone = 'none'
    },
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    cancelForm() {
      this.$router.push({ name: 'home' })
    },
    setUserData(data: UserData) {
      this.userData = {
        ...data,
      }
    },
    formatDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'short' }).format(date)
      } else {
        return '-'
      }
    },
    goBack() {
      this.$router.back()
    },
    submit() {
      this.busy = true
      const loading = this.$loading({
        target: '.form-panel',
        text: 'Chargement des données...',
      })
      const userIdToEdit = this.userData.oldUserId ?? this.user.id
      this.$api
        .put(`/user/${userIdToEdit}`, this.userData)
        .then((response) => {
          const apiResponse = response.data

          this.setUserData(apiResponse.data)
          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Utilisateur modifié avec succès !',
          })
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          this.changeDetected = false
          loading.close()
        })
    },
  },
})
